import Kindergarten from '@/components/04-kindergarten/04-kindergarten';
import FooterOne from '@/components/Footer/Footer-One';
import HeaderStyleEight from '@/components/Header/HeaderStyle-Eight';
import MobileMenu from '@/components/Header/MobileMenu';
import Context from '@/context/Context';
import Store from '@/redux/store';
import { Provider } from 'react-redux';
import PageHead from '../Head';
import BackToTop from '../backToTop';

const Home = () => {
  return (
    <>
      <PageHead title="Indian Chess Academy" />
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleEight headerSticky="rbt-sticky" headerType="" />
          <Kindergarten />

          <BackToTop />
          <FooterOne />
        </Context>
      </Provider>
    </>
  );
};

export default Home;
