import Image from 'next/image';
import Link from 'next/link';
import CallToAction from '../Call-To-Action/CallToAction';
import CallToActionFive from '../Call-To-Action/CallToAction-Five';
import CallToActionFour from '../Call-To-Action/CallToAction-Four';
import CallToActionSix from '../Call-To-Action/CallToAction-Six';
import CallToActionThree from '../Call-To-Action/CallToAction-Three';
import CallToActionTwo from '../Call-To-Action/CallToAction-Two';
import CourseDetailsOne from '../Course-Details/CourseDetails-One';
import Gallery from '../Gallery/Gallery';
import ServiceEleven from '../Services/Service-Eleven';
import TeamFive from '../Team/TeamFive';
import Testimonial from '../Testimonials/Testimonial';

const Kindergarten = () => {
  return (
    <>
      <div
        className="rbt-banner-area rbt-banner-4 bg_image bg_image--13 header-transperent-spacer"
        style={{ paddingTop: '140px' }}
      >
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content text-start">
                  <div className="inner">
                    <h1 className="title color-white">
                      Putting Your Child&apos;s Chess <br /> in Great Motion.
                    </h1>
                    <p className="description color-white">
                      We just don&apos;t give our student only <br /> lecture
                      but real life experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-wrapper color-info bg-gradient-5 pb--50 pt--50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h1 className="title color-secondary ">Indian Chess Academy</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallToActionTwo />

      <div className="service-wrapper rbt-section-gap bg-color-white">
        <div className="container">
          <div className="row mb--30">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title">Our Curriculum</h2>
                <p className="description has-medium-font-size mt--20">
                  We offer chess coaching for all skill levels, from beginners
                  learning the ropes to advanced players looking to refine their
                  strategies. Whether you're a newcomer to the board or a
                  seasoned competitor, our coaches can tailor a program to help
                  you achieve your chess goals.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ServiceEleven isTitle={false} />
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-team-area bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row mb--60">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title">Meet Our Teachers.</h2>
                <p className="description has-medium-font-size">
                  There are many variations of passages of the Ipsum available,
                  but the majority have suffered alteration in some form, by
                  injected humour.
                </p>
              </div>
            </div>
          </div>
          <TeamFive isHead={false} />
        </div>
      </div>

      <div className="rbt-testimonial-area bg-color-light rbt-section-gap overflow-hidden">
        <div className="wrapper mb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="title">Parents / Students Review!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonial />
      </div>

      <Gallery />
    </>
  );
};

export default Kindergarten;
